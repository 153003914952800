/*
 * @Author: mulingyuer
 * @Date: 2021-07-27 11:24:28
 * @LastEditTime: 2021-07-27 13:55:17
 * @LastEditors: mulingyuer
 * @Description: 证书其他配置
 * @FilePath: \saas-admin-vue\src\modules\organization-admin\api\cyc-other-config.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";
//获取配置
export const memberConfig = data => {
  return api({
    url: "/admin/cert/cert/memberConfig",
    method: "post",
    data
  })
}

//保存配置
export const saveConfig = data => {
  return api({
    url: "/admin/cert/cert/saveMemberConfig",
    method: "post",
    data
  })
}

//证书列表
export const certList = data => {
  return api({
    url: "/admin/cert/cert/certList",
    method: "post",
    data
  })
}
export const getIndexData = () => {
  return api({
    url: '/admin/app_design/project/index',
    method: 'post'
  })
}

/* 联系方式 */
export const getOtherContact = () => {
  return api({
    url: '/admin/cyc/settings/getContactConfig',
    method: 'post'
  })
}
export const saveOtherContact = (data) => {
  return api({
    url: '/admin/cyc/settings/setContactConfig',
    method: 'post',
    data
  })
}
//获取会员编号
export const getCycMemberNumberConfig = ()=>{
  return api({
    url:"/admin/cyc/settings/getCycMemberNumberConfig",
    method: "post",
  })
}
// 保存会员编号
export const setCycMemberNumberConfig = data =>{
  return api({
    url:"/admin/cyc/settings/setCycMemberNumberConfig",
    method: "post",
    data,
  })
}
//获取会员编号模版
export const importMemberNumbersXlsx = ()=>{
  return fetchFile({
    url:"/admin/cyc/user_op/importMemberNumbersXlsx",
    method: "post",
    data:{},
    download: true
  })
}
// 批量导入会员
export const importMemberNumbers = (data) => {
  return api({
    url: '/admin/cyc/user_op/importMemberNumbers',
    method: 'post',
    data,
    notCancel: true
  })
}
//获取上级组织架构
export const getOrganizationsTree = data => {
  return api({
    url: "/admin/cyc/Organization/OrganizationsTree",
    method: "post",
    data
  });
};