<template>
  <div class="position-show-config">
    <div class="list-content" v-loading="loading">
      <div class="config-item" v-if="otherConfig !== null">
        <div class="config-title">联系秘书</div>
        <div class="config-content">
          <el-form
            class="small-form"
            size="small"
            label-width="100px"
            label-position="right"
          >
            <el-form-item label="上传图片：">
              <single-media-wall
                v-model="otherConfig.cyc_register_contact"
                width="211px"
                height="211px"
                fit="contain"
              >
              </single-media-wall>
              <div class="form-tip" style="margin-top: 10px">
                <span
                  class="el-icon-info"
                  style="font-size: 15px; color: #c0c4cc"
                ></span>
                <span
                  >上传“我的二维码”，适用于用户提交入会申请、微信公众号文章引用内容有问题可以联系到我</span
                >
                <!--                <spanJ>建议上传尺寸比例为211*280</spanJ>-->
              </div>
            </el-form-item>
            <el-form-item label="效果预览：">
              <el-link
                type="primary"
                :underline="false"
                @click="
                  $previewImage([
                    // require('../../assets/images/other-config/contact-me-preview.png'),
                    `${baseImgUrl}/organization-admin/contact-me-preview.png`
                  ])
                "
                >点击预览</el-link
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!--      <div class="config-item" v-if="config !== null">-->
      <!--        <div class="config-title">效果预览</div>-->
      <!--        <div class="config-content">-->
      <!--          <img height="567" src="" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="config-item" v-if="otherConfig !== null">
        <div class="config-title">加入群聊</div>
        <div class="config-content">
          <el-form
            :model="otherConfig.cyc_group_chat"
            :rules="rules"
            ref="chatConfigForm"
            class="small-form"
            size="small"
            label-width="100px"
            label-position="right"
          >
            <el-form-item label="群聊名称：">
              <el-input
                v-model="otherConfig.cyc_group_chat.name"
                placeholder="请输入群聊名称"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="封面图："
              :prop="otherConfig.cyc_group_chat.name ? 'cover' : ''"
            >
              <single-media-wall
                ratio="104:104"
                v-model="otherConfig.cyc_group_chat.cover"
                width="104px"
                height="104px"
                fit="contain"
              >
              </single-media-wall>
              <el-input
                v-model="otherConfig.cyc_group_chat.cover"
                v-show="false"
              ></el-input>
              <div class="info" style="margin-top: 10px">
                <i class="el-icon-info" />
                <span>建议上传尺寸比例为104*104</span>
              </div>
            </el-form-item>
            <el-form-item
              label="上传二维码："
              :prop="otherConfig.cyc_group_chat.name ? 'qrcode' : ''"
            >
              <single-media-wall
                v-model="otherConfig.cyc_group_chat.qrcode"
                width="211px"
                height="211px"
                fit="contain"
              >
              </single-media-wall>
              <div class="info" style="margin-top: 10px">
                <i class="el-icon-info" />
                <span
                  >上传“群聊二维码”，适用于用户完成会费缴纳后邀请入群（群聊二维码有过期时间，需要定期更换）</span
                >
                <!--                <spanJ>建议上传尺寸比例为211*280</spanJ>-->
              </div>
            </el-form-item>
            <el-form-item
              label="二维码过期提醒："
              label-width="120px"
              :prop="otherConfig.cyc_group_chat.name ? 'expired_at' : ''"
            >
              <el-date-picker
                v-model="otherConfig.cyc_group_chat.expired_at"
                type="date"
                value-format="timestamp"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="效果预览：">
              <el-link
                type="primary"
                :underline="false"
                @click="
                  $previewImage([
                    require('../../assets/images/other-config/join-group-chat-preview.png'),
                  ])
                "
                >点击预览</el-link
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!--      <div class="config-item" v-if="chatConfig !== null">-->
      <!--        <div class="config-title">效果预览</div>-->
      <!--        <div class="config-content">-->
      <!--          <img height="567" src="../../assets/images/other-config/join-group-chat-preview.png" />-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <fixed-action-bar>
      <el-button type="primary" :loading="loading" @click="saveConfig"
        >保存</el-button
      >
    </fixed-action-bar>
  </div>
</template>

<script>
import FixedActionBar from "../../../../base/layout/FixedActionBar";
import SingleMediaWall from "../../../common/components/SingleMediaWall";
import {
  getOtherContact,
  saveOtherContact,
} from "@/modules/organization-admin/api/cyc-other-config";

export default {
  components: { SingleMediaWall, FixedActionBar },
  data() {
    return {
      baseImgUrl: '',
      loading: true,
      otherConfig: null,
      rules: {
        name: [{ required: true, message: "请输入群聊名称", trigger: "blur" }],
        cover: [{ required: true, message: "请上传封面图", trigger: "change" }],
        qrcode: [
          { required: true, message: "请上传二维码", trigger: "change" },
        ],
        expired_at: [
          {
            required: true,
            message: "请选择二维码过期日期",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.getConfig();
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
  },
  methods: {
    saveConfig() {
      this.$refs["chatConfigForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let config = {
            ...this.otherConfig,
            cyc_group_chat: {
              ...this.otherConfig.cyc_group_chat,
              expired_at: this.otherConfig.cyc_group_chat.expired_at / 1000,
            },
          };
          saveOtherContact({ config })
            .then((res) => {
              this.$message.success(res.msg);
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getConfig() {
      getOtherContact()
        .then((res) => {
          this.otherConfig = Object.assign(
            {
              cyc_register_contact: "",
              cyc_group_chat: {
                name: "",
                cover: "",
                qrcode: "",
                expired_at: "",
              },
            },
            res.data
          );
          this.otherConfig.cyc_group_chat.expired_at =
            res.data.cyc_group_chat.expired_at * 1000;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.position-show-config {
  .list-item {
    min-height: 100px;
  }

  .config-item + .config-item {
    /*margin-top: 79px;*/
  }

  .config-item {
    padding-top: 20px;
    .config-title {
      padding-left: 8px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      border-left: 3px #3479ef solid;
    }
    .config-content {
      margin-top: 32px;
      padding-left: 11px;
      overflow-x: auto;
      .switch-config {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .form-title {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 14px;
          margin-right: 15px;
        }
      }
      .form-tip {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
        line-height: 12px;
        span + span {
          margin-left: 4px;
        }
      }
      .suffix {
        line-height: 34px;
        margin-left: 12px;
        color: rgba(0, 0, 0, 0.85);
      }

      .el-input-number {
        max-width: 140px;
      }
    }
  }

  .flex-col {
    .el-radio {
      line-height: 32px;
    }
  }
}
</style>
